import { Col, Container, Row } from "react-bootstrap";
import CustomLink from "../../../../Components/CustomLink/Index";
import { useState } from "react";

import refer_a_friend from '../../../../Assets/Images/illustration-refer-a-friend.png';

export default function DashboardReferAFriend(){

  const [text_is_copied, set_text_is_copied] = useState(false);

  return (<div>

      <img src={refer_a_friend} alt="Illustration Refer A Friend" className="d_illustration" />

      <Container>
        <Row>
          <Col lg={12}>
            <div className="dashboard_content_panel light">
              <h1>My Liberty Refer a Friend</h1>
              <p className="d_infoParagraph">The person who referred you will NOT be able to see your name. To change it <CustomLink to="/">click here.</CustomLink></p>
              <div className={`d_copy_text ${text_is_copied?'copied':''}`}>
                Share Liberty <span onClick={()=>{
                  var copyText = document.getElementById("input_for_copy");

                  // Select the text field
                  copyText.select();
                  copyText.setSelectionRange(0, 99999); // For mobile devices

                  // Copy the text inside the text field
                  navigator.clipboard.writeText(copyText.value);


                  set_text_is_copied(true);


                }}>https://app.stage.libertyblockchain.co...</span>
                <input type="text" value="https://app.stage.libertyblockchain.co" id="input_for_copy" readOnly />
              </div>
              <hr className="d_hr" />
              <div className="d_centeredTexts">
                <h5>Looks like you don't have any referrals yet.</h5>
                <p>Share your referral link above and spread the word!</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>);
}