import { Col, Container, Row } from "react-bootstrap";
import CustomLink from "../CustomLink/Index";


/*import footer_logo from '../../Assets/Images/footer-logo.png';
import icon_facebook from '../../Assets/Images/icon-social-facebook.png';
import icon_twitter from '../../Assets/Images/icon-social-twitter.png';
import icon_instagram from '../../Assets/Images/icon-social-instagram.png';*/
import footer_logo from '../../Assets/Images/pdg_logo@2x.png';
import icon_facebook from '../../Assets/Images/facebook.png';
import icon_twitter from '../../Assets/Images/twitter.png';
import icon_instagram from '../../Assets/Images/instagram.png';

export default function ZFooter(){
  return (<footer>
      <Container>
        <Row>
          <Col lg={12}>
            <CustomLink className="footer_main_link" to="/">
              <img src={footer_logo} alt="Main Site Link" />
            </CustomLink>

            <ul className="footer_social_icons">
              <li>
                <CustomLink to="#facebook" >
                  <img src={icon_facebook} alt="Facebook" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to="#twitter" >
                  <img src={icon_twitter} alt="Twitter" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to="#instagram" >
                  <img src={icon_instagram} alt="Instagram" />
                </CustomLink>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>);
}