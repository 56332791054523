import BigHeaderWithContent from "../../Components/BigHeaderWithContent/Index";
import Content from "../../Components/Content/Index";
import ContentCentered2 from "../../Components/ContentCentered2/Index";

export default function PageBlogSinglePost({data}){

  console.log(data);

  return (<div className="page">
  
    <BigHeaderWithContent
      margin_bottom="add_margin"
      isForPost={true}
      PostTitle={data.title}
      PostPhoto={data.photo}
      content={<div>
        
          <p><i>{data.date}</i></p>
          <h1>{data.title}</h1>
          {/*<CustomLink to="./Participate" className="btn btn-success">Join Us</CustomLink>*/}
          {data.contentBig}
        </div>}
    />


    <Content 
      addListBoxesPosts={true}
    />
    <ContentCentered2 /> 

  </div>);
}