import BigHeaderWithContent from "../../Components/BigHeaderWithContent/Index";
import CustomLink from "../../Components/CustomLink/Index";
import planet_orange from '../../Assets/Images/planet-orange.png';
import ContentCentered2 from "../../Components/ContentCentered2/Index";
import Content from "../../Components/Content/Index";
import { OriginDomain } from "../../Help/Functions";
import { Button } from "react-bootstrap";

export default function OurMission(){
  
  return (<div className="page">
    <BigHeaderWithContent
      margin_bottom="add_margin"
      thePlanet={planet_orange}
      coverPhoto="the_people_look"
      content={<div>
          {/*<h1>Pioneer Development<br />
          Group - Creators<br />
          of the Liberty<br />
          Blockchain.
          </h1>*/}
          {/*<h1>Pioneer Development<br />
          Group<br/>
          Liberty Blockchain.
          </h1>*/}
          <h1>Pioneer Development<br />
          Group
          </h1>
          {/*<CustomLink to={`${OriginDomain()}/JoinUs`} className="btn btn-success">Join Us</CustomLink>*/}
          
          <Button variant='success' onClick={()=>{
            document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' });
          }}>Join Us</Button>
        </div>}
    />

    <Content
      bg_type="planets-and-stars"
      margin_bottom="mb-0"
      contentCenteredBoxes={
        [
          <div><h3>About Us</h3><p>Pioneer Development Group (PDG) is a Veteran-led technology development company focused on delivering Web 3 applications to the people.</p></div>,
          <div><h3>OUR MISSION</h3><p>Pioneer Development Group’s mission is to deliver engaging Web 3 applications on the Liberty Blockchain. PDG has created the Liberty Blockchain to foster a free and open society.</p></div>,
        ]
      }
    />

    <ContentCentered2 /> 
  </div>);
}