import { Button, Col, Container, Row } from 'react-bootstrap';
import CustomLink from '../CustomLink/Index';
import PDG_Logo_Black_RGB from '../../Assets/Images/PDG_Logo_Black_RGB.png';
import icon_user from '../../Assets/Images/icon-user.png';
import { useEffect, useState } from 'react';
import { EVENT_POPSTATE, ON_SCROLL_TO_THE_FORM_FROM_HEADER } from '../../Help/Constants';
import { ShowStandardElements } from '../../Help/Functions';



//import LitePaper_PDF from '../../Assets/Documents/Liberty Litepaper - v1.4.pdf';
//import LitePaper_PDF from '../../Assets/Documents/Liberty Litepaper - v1.4-2.pdf';
//import LitePaper_PDF_2 from '../../Assets/Documents/Liberty Litepaper - v1.4-2 - Copy.pdf';
import LitePaper_PDF_2 from '../../Assets/Documents/Liberty Litepaper - v1.4-2-2023.7.4.pdf';






export default function Header({isForMobile}){

  const [activeMenuItem, setActiveMenuItem] = useState(window.location.pathname);
  const [hamburgIsActive, setHamburgIsActive] = useState(false);
  //const [showMe, setShowMe] = useState(true);

  const menuItems = [
    //['/Participate', 'Participate'],
    //['/JoinUs', 'Join Us'],
    ['/JoinUs', 'Explore'],
    //['/OurMission', 'Our Mission'],
    ['/Mission', 'Mission'],
    ['/News', 'News'],
    //['/Shop', 'Shop'],
    [LitePaper_PDF_2, 'Lite Paper'],
    //['/Swag', 'Swag'],
    //['/LitePaper', 'Lite Paper'],
  ];

  const ___OnBrowserLinkChange = ()=>{
    setActiveMenuItem(window.location.pathname);
    setHamburgIsActive(false);
    document.body.classList.remove('menu_is_visible');

    /*if(ShowStandardElements() && !showMe){
      setShowMe(true);
    }
    else if(!ShowStandardElements() && showMe){
      setShowMe(false);
    }*/
  }
  const ___ON_SCROLL_TO_THE_FORM_FROM_HEADER = ()=>{
    setHamburgIsActive(false);
    document.body.classList.remove('menu_is_visible');
  }

  console.log('Header is reloading');
  useEffect(()=>{
    
    window.addEventListener(EVENT_POPSTATE, ___OnBrowserLinkChange);
    window.addEventListener(ON_SCROLL_TO_THE_FORM_FROM_HEADER, ___ON_SCROLL_TO_THE_FORM_FROM_HEADER);

    return ()=>{
      window.removeEventListener(EVENT_POPSTATE, ___OnBrowserLinkChange);
      window.removeEventListener(ON_SCROLL_TO_THE_FORM_FROM_HEADER, ___ON_SCROLL_TO_THE_FORM_FROM_HEADER);
    }
  }, [])

  /*if(!showMe)
    return null;*/

  return (<header className={`${isForMobile===true?'is_for_mobile':''} ${hamburgIsActive?'make_header_for_active_hamburg':''}`}>
    <Container>
      <Row>
        <Col lg={12}>

        

          {
            isForMobile
            ?
            <div className='header_left_menu_set'>
              <CustomLink to="/" className='header_main_logo header_main_logo_left'>
                <img src={PDG_Logo_Black_RGB} alt="Liberty" />
              </CustomLink>
              
              <Button variant='warning' className={`hamburg_button ${hamburgIsActive?'clicked':''}`} onClick={()=>{
                setHamburgIsActive(!hamburgIsActive);

                if(!hamburgIsActive===true){
                  document.body.classList.add('menu_is_visible');
                }
                else{
                  document.body.classList.remove('menu_is_visible');
                }

              }}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </Button>
            </div>
            :
            null
          }
          {
            isForMobile
            ?
            null
            :
            <div className='header_left_menu_set'>
              <CustomLink to="/" className='header_main_logo header_main_logo_left'>
                <img src={PDG_Logo_Black_RGB} alt="Liberty" />
              </CustomLink>
              <ul className="header_left_menu">
                {
                  menuItems.map((details, key)=>{
                    return <li key={`header-menu-item-${key}`} className={`${activeMenuItem===details[0]?'active':''}`}>
                      <CustomLink to={`${details[0]}`}>{details[1]}</CustomLink>
                    </li>;
                  })
                }
              </ul>
            </div>
          }

          {/*<CustomLink to="./" className='header_main_logo'>
            <img src={PDG_Logo_Black_RGB} alt="Liberty" />
          </CustomLink>*/}


          {
            isForMobile
            ?
            null
            :
            <ul className="header_menu_user">
              <li>
                {
                  /*<CustomLink to="/User/Login" className="btn_icon_header">
                    <img src={icon_user} alt="Login" /> Login
                  </CustomLink>*/
                }
                {
                  <CustomLink to="https://app.libertyblockchain.com/" className="btn_icon_header">
                    <img src={icon_user} alt="Login" /> Login
                  </CustomLink>
                }
              </li>
              <li>
                {/*<CustomLink to="./ConnectWallet" className="btn btn-warning btn_header">Connect Wallet</CustomLink>*/}
                {/*<CustomLink to="./ConnectWallet" className="btn btn-warning btn_header">Join Us</CustomLink>*/}
                <Button variant='warning' className='btn_header' onClick={()=>{
                  document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' });
                  //___OnBrowserLinkChange();
                  window.dispatchEvent(new CustomEvent(ON_SCROLL_TO_THE_FORM_FROM_HEADER));
                }}>Join Us</Button>
              </li>
            </ul>
          }
          

        </Col>
      </Row>
    </Container>
  </header>);
}