import { Col, Container, Row } from "react-bootstrap";
import CustomLink from "../CustomLink/Index";
import left_circle from '../../Assets/Images/left-yellow-circle.png';
import right_circle from '../../Assets/Images/right-saturn.png';
import { OriginDomain } from "../../Help/Functions";

export default function ContentCentered2(){
  
  return (<section className="content_centered_2">
    <img className="left_circle" src={left_circle} alt="Jupiter" />
    <img className="right_circle" src={right_circle} alt="Saturn" />
    <Container>
      <Row>
        <Col lg={12}>
          {/*<h2>Take a stand for<br/>freedom and openness.</h2>*/}
          <h2>TAKE A STAND FOR<br/>FREEDOM AND OPENNESS</h2>
          <p>Be a pioneer. Join us today, buy a node, and make the internet of tomorrow look like<br/>the place you want it to be.</p>
          <div className="buttons_holder">
            <CustomLink className="btn btn-success disabled" to="/BuyALightNode">BUY A LIGHT NODE</CustomLink>
            <CustomLink className="btn btn-primary disabled" to="/BuyANode">BUY A NODE</CustomLink>
          </div>
        </Col>
      </Row>
    </Container>
  </section>);
}