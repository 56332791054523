/*import the_man_that_look from '../../Assets/Images/x3-the-man-that-look.jpg';
import the_beach from '../../Assets/Images/x3-wow-the-beach.jpg';
import the_couple from '../../Assets/Images/x3-the-lovely-couple-work-together.jpg';*/

import { BlogPostsData } from "../../Help/BlogData";
import { NavigateToPage, OriginDomain } from "../../Help/Functions";
import livberty_logo from '../../Assets/Images/livberty_logo.png';


export default function ListBoxesPosts(){

  /*const BlogPostsData = [
    {
      photo:the_man_that_look,
      date:'April 18, 2023',
      content:<span><h4>Duis consectetur nisi non nibh eget blandit diam consequat.</h4></span>,
    },
    {
      photo:null,
      date:'April 22, 2023',
      content:<span><h4>No Blog Image</h4></span>,
    },
    {
      photo:the_couple,
      date:'April 30, 2023',
      content:<span><h4>Donec cursus tristique dapibus. Duis ac ultricies purus.</h4></span>,
    },
  ];*/

  return (
    <ul className="list_yellow_boxes list_box_posts">
    {
      BlogPostsData.map((details, key)=>{
        return <li key={`liar_box_post_${key}`} onClick={()=>{
          //Here function for redirecting to the posts
          NavigateToPage(`${details.route}`)
        }}>
          {(details.photo===null?null:<img src={details.photo} alt="Cover" />)}
          <span className="date">{details.date}</span>
          {details.content}
        </li>;
      })
    }
    </ul>
  );
}