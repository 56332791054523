import BigHeaderWithContent from "../../Components/BigHeaderWithContent/Index";
import Content from "../../Components/Content/Index";
import ContentCentered2 from "../../Components/ContentCentered2/Index";

export default function News(){
  return (<div className="page">

    <BigHeaderWithContent
      margin_bottom="add_margin"
      content={<div>
          <h1>THE LIBERTY<br/>STAR DISPATCH</h1>
          {/*<CustomLink to="./Participate" className="btn btn-success">Join Us</CustomLink>*/}
        </div>}
    />

    <Content 
      margin_bottom="mb-0"
      addListBoxesPosts={true}
    />

    <ContentCentered2 /> 
  </div>);
}